.header-menu ul li a {
    display: block;
    text-transform: capitalize;
    /* color: white; */
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 700;
}

.header-menu .active {
    background: #06A3DA !important;
    padding: 5px;
    border-radius: 5px;
}

.navbar-profile-menu li a {
    font-size: 15px !important;
    margin: 8px 0px !important;
}
.primary {
    background: #EEF9FF;
}


.slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    background: #06A3DA;
    left: 0;
    width: 30px;
    border-radius: 5px;
    height: 15px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #000;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 11px;
    padding: 0;
    cursor: pointer;
}
.service-icon {
    width: 60px;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #149dcf;
    border-radius: 2px;
    transform: rotate(-45deg);
}




.work-icon {
    margin-bottom: 30px;
    width: 80px;
    height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #149dcf;
    border-radius: 4px;
}
.member-logo {
    position: relative;
}

.member-logo::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: 0.5s ease-in-out;
}

.member-logo:hover::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(33, 17, 179, 0.663);
    transition: 0.5s ease-in-out;
}

.member-logo:hover::after img {
    transform: scale(1, 5)
}



.team-social ul {
    visibility: hidden;
}

.member-logo:hover .team-social ul {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    visibility: visible;
    transition: 0.5s;
}

.member-logo:hover .team-social ul {
    visibility: visible;
    animation: ease-out;
}
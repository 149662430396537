.hero-item {
    position: relative;
}

.hero-item::after {
    content: '';
    background: rgba(0, 0, 0, .7);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.hero-content {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 99999;
}


@media all and (max-width : 767px) {
    .hero-content h3 {
        font-size: 16px;
        display: none;
    }

    .hero-content h2 {
        font-size: 30px;
    }

    .hero-content button:first-child {
        padding: 5px;
        text-transform: capitalize;
        font-size: 16px;
    }

    .hero-content button:last-child {
        padding: 5px;
        text-transform: capitalize;
        font-size: 16px;
    }

    .counter-top {
        /* top: 100px; */
        bottom: -400px;
    }

    .choose-section {
        margin-top: 500px;
    }

    .hero-btn {
        display: none;
    }

}

@media all and (max-width : 320px) {
    .hero-content h3 {
        font-size: 20px;
        display: none;
    }

    .hero-content h2 {
        font-size: 30px;
    }

    .hero-btn {
        display: none;
    }

    .counter-top {
        bottom: -380px;
    }

    .choose-section {
        margin-top: 600px;
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
input,
table,
button {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #091E3E;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

section {
  overflow: hidden;
}

.main-title {
  color: #06A3DA !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
}

.main-sub {
  font-size: 38px;
  font-weight: 800;

}

.main-sub2 {
  font-size: 24px;
  font-weight: 700;

}

.main-btn {
  background: #06A3DA;
  color: white;
  border-radius: 5px;
  padding: 15px;
  font-size: 17px;
  font-weight: 700;
}

.extra-color {
  color: #06A3DA;
}

.title-design {
  width: 160px;
  height: 5px;
  margin: auto;
  background: #06A3DA;
  margin-top: 8px;
  border-radius: 5px;
  position: relative;
  transition: 0.5s;
}

.title-design2 {
  width: 160px;
  height: 5px;
  background: #06A3DA;
  margin-top: 8px;
  border-radius: 5px;
  position: relative;
  transition: 0.5s;
}


.title-design::before {
  left: 50%;
  margin-left: -75px;
}


.title-design::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 5px;
  left: 0;
  bottom: 0;
  background: var(--primary);
  border-radius: 2px;
}

.title-design::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 5px;
  bottom: 0px;
  background: #FFFFFF;
  animation: title-design 5s infinite linear;
}

/* custom modal  */
.projectModal {
  width: 90%;
  margin: auto;
}


.common-item {
  transition: all 0.5s ease-in-out;
}

.common-item:hover {
  transform: translateY(15px);
}

















.Toastify__toast-body {
  font-family: 'Nunito', sans-serif;

}

.react-responsive-modal-modal {
  border-radius: 15px;
}

/* custom loader */
.loader {
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.footer-shape {
    width: 100px;
    height: 4px;
    background: #06A3DA;
    margin-top: 8px;
    border-radius: 5px;
    position: relative;
    transition: 0.5s;
}


.footer-menu li a {
    transition: 0.5s;
    text-transform: capitalize;
    margin: 5px 0px;
}

.footer-menu li a:hover {
    margin-left: 10px;
}